import { render, staticRenderFns } from "./index.vue?vue&type=template&id=19a56802&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBreadcrumbs: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/app/Breadcrumbs.vue').default,FormLogin: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/form/Login.vue').default,FormRegister: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/7/a/NuxtApp/components/form/Register.vue').default})
